@keyframes placeholder-progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25;
  color: #1c1d1d;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 700;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #718096;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 1024px) {
  .container {
    width: 1280px;
  }
}
body {
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 300;
}

h1, h2, h3, .h1, .h2, .h3 {
  font-family: "Inter", serif;
  color: #000;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
}

h4, h5, h6, .h4, .h5, .h6 {
  font-family: "Inter", sans-serif;
  color: #000;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
}

h1, .h1 {
  font-size: 72px;
}

h2, .h2 {
  font-size: 44px;
}

h3, .h3 {
  font-size: 36px;
}

h4, .h4 {
  font-size: 24px;
}

h5, .h5 {
  font-size: 18px;
  font-weight: 300;
}

h6, .h6 {
  font-size: 16px;
  font-weight: 300;
}

p {
  font-family: "Inter", sans-serif;
  color: #1c1d1d;
  margin-bottom: 10px;
  line-height: 22px;
}
p:last-child {
  margin-bottom: 0;
}

a {
  transition: color 0.1s;
  outline: none;
}

button {
  border: none;
}
button:focus {
  outline: none;
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #0078ff;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 20px;
  border-radius: 5px;
  box-shadow: none;
  transition: background-color 0.15s ease-out;
  text-shadow: none;
  border: none;
  position: relative;
}
.btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: rgba(28, 29, 29, 0.1);
  opacity: 0;
  transition: opacity 0.15s ease-out;
  pointer-events: none;
}
.no-touchevents .btn:hover {
  outline: 0;
  text-decoration: none;
  box-shadow: none;
}
.no-touchevents .btn:hover:before {
  opacity: 1;
}

.btn:focus, .btn.focus {
  outline: 0;
}
.btn:focus:before, .btn.focus:before {
  opacity: 1;
}
.btn.disabled, .btn:disabled {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.no-touchevents .btn.disabled:hover, .no-touchevents .btn:disabled:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.no-touchevents .btn.disabled:hover:before, .no-touchevents .btn:disabled:hover:before {
  display: none;
}

.btn--secondary {
  color: #000;
  border: 1px solid #e5e5e5;
  background-color: #f5f5f5;
}
.btn--secondary.disabled, .btn--secondary:disabled {
  background-color: rgba(0, 0, 0, 0.25);
}
.no-touchevents .btn.disabled:hover, .no-touchevents .btn:disabled:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.no-touchevents .btn.disabled:hover:before, .no-touchevents .btn:disabled:hover:before {
  display: none;
}

.btn--white {
  color: rgba(28, 29, 29, 0.5);
  border: 1px solid #e5e5e5;
  background-color: #fff;
  transition: color 0.15s ease-out;
}
.no-touchevents .btn--white:hover {
  background-color: #fff;
}
.no-touchevents .btn--white:hover:before {
  display: none;
}
.no-touchevents .btn--white:hover span, .no-touchevents .btn--white:hover svg {
  color: #0078ff;
}

.btn--white:focus, .btn--white.focus {
  background-color: #fff;
}
.btn--white:focus:before, .btn--white.focus:before {
  display: none;
}
.btn--white:focus span, .btn--white:focus svg, .btn--white.focus span, .btn--white.focus svg {
  color: #0078ff;
}
.btn--white.disabled, .btn--white:disabled {
  background-color: rgba(0, 0, 0, 0.25);
}
.no-touchevents .btn--white.disabled:hover, .no-touchevents .btn--white:disabled:hover {
  background-color: rgba(0, 0, 0, 0.25);
}
.no-touchevents .btn--white.disabled:hover span, .no-touchevents .btn--white.disabled:hover svg, .no-touchevents .btn--white:disabled:hover span, .no-touchevents .btn--white:disabled:hover svg {
  color: rgba(28, 29, 29, 0.5);
}

.btn--black {
  color: #fff;
  background-color: #1c1d1d;
}
.no-touchevents .btn--black:hover {
  background-color: #000;
}
.no-touchevents .btn--black:hover:before {
  display: none;
}

.btn--black:focus, .btn--black.focus {
  background-color: #000;
}
.btn--black:focus:before, .btn--black.focus:before {
  display: none;
}
.btn--black.disabled, .btn--black:disabled {
  background-color: rgba(28, 29, 29, 0.25);
}
.no-touchevents .btn--black.disabled:hover, .no-touchevents .btn--black:disabled:hover {
  background-color: rgba(28, 29, 29, 0.25);
}

.btn--on-black-bg {
  color: rgba(255, 255, 255, 0.75);
  background-color: rgba(255, 255, 255, 0.1);
}
.no-touchevents .btn--on-black-bg:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.no-touchevents .btn--on-black-bg:hover:before {
  display: none;
}

.btn--on-black-bg:focus, .btn--on-black-bg.focus {
  background-color: #000;
}
.btn--on-black-bg:focus:before, .btn--on-black-bg.focus:before {
  display: none;
}
.btn--on-black-bg.disabled, .btn--on-black-bg:disabled {
  background-color: rgba(28, 29, 29, 0.25);
}
.no-touchevents .btn--on-black-bg.disabled:hover, .no-touchevents .btn--on-black-bg:disabled:hover {
  background-color: rgba(28, 29, 29, 0.25);
}

.btn--accent-red {
  color: #fff;
  background-color: #fb4747;
}
.no-touchevents .btn--accent-red:hover {
  background-color: #fb4747;
}

.btn--accent-red:focus, .btn--accent-red.focus {
  background-color: #fb4747;
}
.btn--accent-red.disabled, .btn--accent-red:disabled {
  background-color: rgba(251, 71, 71, 0.25);
}
.no-touchevents .btn--accent-red.disabled:hover, .no-touchevents .btn--accent-red:disabled:hover {
  background-color: rgba(251, 71, 71, 0.25);
}

.btn--accent-green {
  color: #fff;
  background-color: #18ab9b;
}
.no-touchevents .btn--accent-red:hover {
  background-color: #18ab9b;
}

.btn--accent-green:focus, .btn--accent-green.focus {
  background-color: #18ab9b;
}
.btn--accent-green.disabled, .btn--accent-green:disabled {
  background-color: rgba(24, 171, 155, 0.25);
}
.no-touchevents .btn--accent-red.disabled:hover, .no-touchevents .btn--accent-red:disabled:hover {
  background-color: rgba(24, 171, 155, 0.25);
}

.btn--marketplace-card {
  color: rgba(28, 29, 29, 0.5);
  background-color: rgba(0, 0, 0, 0.05);
}
.no-touchevents .btn--marketplace-card:hover {
  background-color: rgba(28, 29, 29, 0.1);
}
.no-touchevents .btn--marketplace-card:hover:before {
  display: none;
}

.btn--marketplace-card:focus, .btn--marketplace-card.focus {
  background-color: rgba(28, 29, 29, 0.1);
}
.btn--marketplace-card:focus:before, .btn--marketplace-card.focus:before {
  display: none;
}
.btn--marketplace-card.disabled, .btn--marketplace-card:disabled {
  background-color: rgba(0, 0, 0, 0.01);
}
.no-touchevents .btn--marketplace-card.disabled:hover, .no-touchevents .btn--marketplace-card:disabled:hover {
  background-color: rgba(0, 0, 0, 0.01);
}

.btn--transparent-white-button {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.15s ease-out;
}
.no-touchevents .btn--transparent-white-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.no-touchevents .btn--transparent-white-button:hover:before {
  display: none;
}

.btn--transparent-white-button:focus, .btn--transparent-white-button.focus {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn--transparent-white-button:focus:before, .btn--transparent-white-button.focus:before {
  display: none;
}
.btn--transparent-white-button.disabled, .btn--transparent-white-button:disabled {
  background-color: rgba(255, 255, 255, 0.1);
}
.no-touchevents .btn--transparent-white-button.disabled:hover, .no-touchevents .btn--transparent-white-button:disabled:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.no-touchevents .btn--transparent-white-button.disabled:hover:before, .no-touchevents .btn--transparent-white-button:disabled:hover:before {
  display: none;
}

.btn--with-icon {
  padding-left: 12px;
  padding-right: 18px;
  display: inline-flex;
  align-items: center;
}
.btn--with-icon svg {
  margin-right: 10px;
}
.btn--lg {
  font-size: 18px;
  padding: 18px 24px 18px;
}

input, select, textarea {
  display: block;
  width: 100%;
  height: 56px;
  padding: 16px 16px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #1c1d1d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: none;
  transition: border 0.15s ease-out, opacity 0.15s ease-out;
}
input::-ms-expand, select::-ms-expand, textarea::-ms-expand {
  background-color: transparent;
  border: 0;
}
input:focus, select:focus, textarea:focus {
  box-shadow: none;
  outline: 0;
  border: 1px solid #0078ff;
}
input::placeholder, select::placeholder, textarea::placeholder {
  color: #1c1d1d;
  opacity: 0.25;
}
input:disabled, input[readonly], select:disabled, select[readonly], textarea:disabled, textarea[readonly] {
  background-color: #fdfdfd;
  opacity: 1;
  cursor: not-allowed;
  box-shadow: none;
  border: 1px solid #cbd5e0;
  color: #4a5568;
}
input:disabled, select:disabled, textarea:disabled {
  cursor: not-allowed;
}

select:focus::-ms-value {
  color: #1a202c;
  background-color: #fff;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%231c1d1d' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: 20px auto, 100%;
  padding-right: 40px;
}
select option {
  font-weight: normal;
}

.filter-select {
  text-align: center;
}
.filter-select label {
  display: none;
}
.filter-select select {
  height: 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 37px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%231c1d1d' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 15px top 10px, 0 0;
  background-size: 20px 20px, 100%;
  text-align-last: center;
}

textarea {
  min-height: 88px;
  resize: none;
  line-height: 22px;
}

input[type=checkbox], input[type=radio] {
  height: auto;
}

.form-group {
  position: relative;
  padding-bottom: 30px;
}
.form-group label {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #000;
}
.form-group label .optional-label {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 300;
}
.form-group__error {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 10px;
  display: block;
  text-align: right;
  font-size: 10px;
  font-weight: 300;
  line-height: 16px;
  color: #0078ff;
}
.form-group--disabled label {
  color: rgba(0, 0, 0, 0.25);
}
.form-group--disabled input, .form-group--disabled select, .form-group--disabled texatarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fdfdfd;
}
.form-group--dark input:focus, .form-group--dark select:focus, .form-group--dark textarea:focus {
  border: 1px solid #1c1d1d;
}

.form-2-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-2-col .form-group {
  flex: 0 1 48%;
}

.dark-theme-input input, .dark-theme-input select, .dark-theme-input textarea {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
  color: #fff;
}
.dark-theme-input input::placeholder, .dark-theme-input select::placeholder, .dark-theme-input textarea::placeholder {
  color: #f5f5f5;
}
.dark-theme-input input:focus, .dark-theme-input select:focus, .dark-theme-input textarea:focus {
  border: 1px solid rgba(255, 255, 255, 0.75);
}
.dark-theme-input input:disabled, .dark-theme-input input[readonly], .dark-theme-input select:disabled, .dark-theme-input select[readonly], .dark-theme-input textarea:disabled, .dark-theme-input textarea[readonly] {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.2);
}
.dark-theme-input select {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%23ffffff' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
}

@media (max-width: 767.98px) {
  .form-2-col .form-group {
    flex: 1 1 100%;
  }
}
.toasted-container.top-right.toasted-container-ply {
  top: 12px;
  right: 12px;
  z-index: 999;
}

.toasted.toasted-primary.toasted-ply {
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 310px;
  line-height: 22px;
  font-size: 14px;
}
.toasted.toasted-primary.toasted-ply:first-child {
  margin-top: 0;
}
.toasted.toasted-primary.toasted-ply > i {
  margin-right: 10px;
}
.toasted.toasted-primary.toasted-ply i {
  font-size: 20px;
}
.toasted.toasted-primary.toasted-ply.error {
  background-color: #fb4747;
}
.toasted.toasted-primary.toasted-ply.info, .toasted.toasted-primary.toasted-ply.success {
  background-color: #0078ff;
}
.toasted.toasted-primary.toasted-ply .action {
  color: #fff;
}
.toasted.toasted-primary.toasted-ply .toast-close i {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='white'%3E%3Cpath d='M15.833 5.342l-1.175-1.175-4.658 4.658-4.658-4.658-1.175 1.175 4.658 4.658-4.658 4.658 1.175 1.175 4.658-4.658 4.658 4.658 1.175-1.175-4.658-4.658z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

@media only screen and (max-width: 600px) {
  .toasted-container.toasted-container-ply {
    min-width: 0;
  }

  .toasted.toasted-primary.toasted-ply {
    max-width: 300px;
  }
}
.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 280px;
  line-height: 1.5;
}
.tooltip .tooltip-inner {
  background: #0078ff;
  color: white;
  border-radius: 2px;
  padding: 6px;
  font-size: 12px;
  font-weight: 500;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
}
.tooltip[x-placement^=top] {
  margin-bottom: 5px;
}
.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}
.tooltip[x-placement^=right] {
  margin-left: 10px;
}
.tooltip[x-placement^=left] {
  margin-right: 5px;
}
.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.tooltip-with-menu .tooltip-inner {
  background-color: transparent;
  border: none;
  padding: 0;
}

.tooltip-menu {
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.tooltip-menu__item {
  width: 100%;
  font-weight: 500;
  color: #000;
  display: block;
  background-color: #fff;
  transition: background-color 0.15s ease-out;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 16px;
  font-size: 14px;
  line-height: 20px;
}
.no-touchevents .tooltip-menu__item:hover {
  background-color: #f5f5f5;
}

#chartjs-tooltip {
  background-color: #0078ff;
  border-radius: 5px;
  padding: 5px 18px;
  transform: translate(-50%, -110%);
  border: none;
}
#chartjs-tooltip table {
  border: none;
  border-collapse: collapse;
  background-color: #0078ff;
}
#chartjs-tooltip table tr {
  border: none;
}
#chartjs-tooltip table tr th {
  background-color: #0078ff;
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 500;
  border: none;
}
#chartjs-tooltip table tr td {
  background-color: #0078ff;
  color: rgba(255, 255, 255, 0.5);
  border: none;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 400;
}