//
// Textual form controls
//

input, select, textarea {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  font-weight: 300;
  line-height: $input-line-height;
  color: $gray-800;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $input-border-color;
  border-radius: 0;
  box-shadow: none;
  transition: border 0.15s ease-out, opacity 0.15s ease-out;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    border: 1px solid $primary;
  }

  // Placeholder
  &::placeholder {
    color: $gray-800;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 0.25;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $gray-200;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
    cursor: not-allowed;
    box-shadow: none;
    border: 1px solid $gray-400;
    color: $gray-700;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

select {
  &:focus::-ms-value {
    // Suppress the nested default white text on blue background highlight given to
    // the selected option text when the (still closed) <select> receives focus
    // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
    // match the appearance of the native widget.
    // See https://github.com/twbs/bootstrap/issues/19398.
    color: $gray-900;
    background-color: #fff;
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%231c1d1d' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 16px top 50%, 0 0;
  background-size: 20px auto, 100%;
  padding-right: 40px;

  option {
    font-weight: normal;
  }
}

.filter-select {
  text-align: center;

  label {
    display: none;
  }

  select {
    height: 40px;
    border-radius: $border-radius;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 37px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%231c1d1d' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 15px top 10px, 0 0;
    background-size: 20px 20px, 100%;
    text-align-last: center;
  }
}

textarea {
  min-height: 88px;
  resize: none;
  line-height: 22px;
}

input[type="checkbox"], input[type="radio"] {
  height: auto;
}

.form-group {
  position: relative;
  padding-bottom: 30px;

  label {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: $black;

    .optional-label {
      font-size: 10px;
      color: rgba($black, 0.5);
      font-weight: 300;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    display: block;
    text-align: right;
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    color: $primary;
  }

  &--disabled {
    label {
      color: rgba($black, 0.25);
    }

    input, select, texatarea {
      color: rgba($black, 0.25);
      background-color: $gray-200;
    }
  }

  &--dark {
    input, select, textarea {
      &:focus {
        border: 1px solid $gray-800;
      }
    }
  }
}

.form-2-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .form-group {
    flex: 0 1 48%;
  }
}

.dark-theme-input {
  input, select, textarea {
    background-color: transparent;
    border: 1px solid rgba($white, 0.25);
    color: $white;

    // Placeholder
    &::placeholder {
      color: $gray-100;
    }

    &:focus {
      border: 1px solid rgba($white, 0.75);
    }

    &:disabled,
    &[readonly] {
      background-color: transparent;
      border: 1px solid rgba($white, 0.1);
      color: rgba($white, 0.2);
    }
  }

  select {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0h24v24H0z'/%3E%3Cpath fill='%23ffffff' opacity='0.25' fill-rule='nonzero' d='M12 16l6-8H6z'/%3E%3C/g%3E%3C/svg%3E");
  }
}

@include media-breakpoint-down(sm) {
  .form-2-col {
    .form-group {
      flex: 1 1 100%;
    }
  }
}