.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@include media-breakpoint-up(lg) {
  .container {
    width: 1280px;
  }
}
