#chartjs-tooltip {
  background-color: $primary;
  border-radius: 5px;
  padding: 5px 18px;
  transform: translate(-50%,-110%);
  border: none;
  table {
    border: none;
    border-collapse: collapse;
    background-color: $primary;
    tr {
      border: none;

      th {
        background-color: $primary;
        color: $white;
        text-align: center;
        font-size: $text-sm;
        line-height: $text-lg;
        font-weight: 500;
        border: none;
      }

      td {
        background-color: $primary;
        color: rgba(255, 255, 255, 0.5);
        border: none;
        text-align: center;
        font-size: $text-xs;
        line-height: $text-lg;
        font-weight: 400;
      }


    }
  }
}