button {
  border: none;

  &:focus {
    outline: none;
  }
}

.btn {
  display: inline-block;
  font-weight: 500;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: $primary;
  padding: $btn-padding-y $btn-padding-x;
  font-size: $btn-font-size;
  line-height: $btn-line-height;
  border-radius: $border-radius;
  box-shadow: none;
  transition: background-color 0.15s ease-out;
  text-shadow: none;
  border: none;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: $border-radius;
    background-color: rgba($gray-800, 0.1);
    opacity: 0;
    transition: opacity 0.15s ease-out;
    pointer-events: none;
  }

  @at-root {
    .no-touchevents {
      .btn:hover {
        &:before {
          opacity: 1;
        }

        outline: 0;
        text-decoration: none;
        box-shadow: none;
      }
    }
  }


  &:focus,
  &.focus {
    outline: 0;
    &:before {
      opacity: 1;
    }
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    box-shadow: none;
    background-color: rgba($black, 0.25);
    cursor: not-allowed;

    @at-root {
      .no-touchevents {
        .btn {
          &.disabled,
          &:disabled {
            &:hover {
              &:before {
                display: none;
              }

              background-color: rgba($black, 0.25);
            }
          }
        }
      }
    }
  }

  &--secondary {
    color: $black;
    border: 1px solid $gray-500;
    background-color: $gray-100;


    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($black, 0.25);

      @at-root {
        .no-touchevents {
          .btn {
            &.disabled,
            &:disabled {
              &:hover {
                &:before {
                  display: none;
                }
                background-color: rgba($black, 0.25);
              }
            }
          }
        }
      }
    }
  }

  &--white {
    color: rgba($gray-800, 0.5);
    border: 1px solid $gray-500;
    background-color: $white;
    transition: color 0.15s ease-out;

    @at-root {
      .no-touchevents {
        .btn--white:hover {
          &:before {
            display: none;
          }

          background-color: $white;
          span, svg {
            color: $primary;
          }
        }
      }
    }


    &:focus,
    &.focus {
      &:before {
        display: none;
      }

      background-color: $white;
      span, svg {
        color: $primary;
      }
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($black, 0.25);

      @at-root {
        .no-touchevents {
          .btn--white {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($black, 0.25);

                span, svg {
                  color: rgba($gray-800, 0.5);
                }
              }
            }
          }
        }
      }
    }
  }

  &--black {
    color: $white;
    background-color: $gray-800;

    @at-root {
      .no-touchevents {
        .btn--black:hover {
          &:before {
            display: none;
          }

          background-color: $black;
        }
      }
    }


    &:focus,
    &.focus {
      &:before {
        display: none;
      }

      background-color: $black;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($gray-800, 0.25);

      @at-root {
        .no-touchevents {
          .btn--black {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($gray-800, 0.25);
              }
            }
          }
        }
      }
    }
  }

  &--on-black-bg {
    color: rgba($white, 0.75);
    background-color: rgba($white, 0.1);

    @at-root {
      .no-touchevents {
        .btn--on-black-bg:hover {
          &:before {
            display: none;
          }

          background-color: rgba($white, 0.2);
        }
      }
    }


    &:focus,
    &.focus {
      &:before {
        display: none;
      }

      background-color: $black;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($gray-800, 0.25);

      @at-root {
        .no-touchevents {
          .btn--on-black-bg {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($gray-800, 0.25);
              }
            }
          }
        }
      }
    }
  }

  &--accent-red {
    color: $white;
    background-color: $accent-error;

    @at-root {
      .no-touchevents {
        .btn--accent-red:hover {
          background-color: $accent-error;
        }
      }
    }


    &:focus,
    &.focus {
      background-color: $accent-error;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($accent-error, 0.25);

      @at-root {
        .no-touchevents {
          .btn--accent-red {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($accent-error, 0.25);
              }
            }
          }
        }
      }
    }
  }


  &--accent-green {
    color: $white;
    background-color: $accent-green;

    @at-root {
      .no-touchevents {
        .btn--accent-red:hover {
          background-color: $accent-green;
        }
      }
    }


    &:focus,
    &.focus {
      background-color: $accent-green;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($accent-green, 0.25);

      @at-root {
        .no-touchevents {
          .btn--accent-red {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($accent-green, 0.25);
              }
            }
          }
        }
      }
    }
  }

  &--marketplace-card {
    color: rgba($gray-800, 0.5);
    background-color: rgba($black, 0.05);

    @at-root {
      .no-touchevents {
        .btn--marketplace-card:hover {
          &:before {
            display: none;
          }

          background-color: rgba($gray-800, 0.1);
        }
      }
    }


    &:focus,
    &.focus {
      &:before {
        display: none;
      }

      background-color: rgba($gray-800, 0.1);
    }

    &.disabled,
    &:disabled {
      background-color: rgba($black, 0.01);

      @at-root {
        .no-touchevents {
          .btn--marketplace-card {
            &.disabled,
            &:disabled {
              &:hover {
                background-color: rgba($black, 0.01);
              }
            }
          }
        }
      }
    }
  }

  &--transparent-white-button {
    color: $white;
    background-color: rgba($white, 0.1);
    transition: background-color 0.15s ease-out;

    @at-root {
      .no-touchevents {
        .btn--transparent-white-button:hover {
          &:before {
            display: none;
          }

          background-color: rgba($white, 0.2);
        }
      }
    }


    &:focus,
    &.focus {
      &:before {
        display: none;
      }
      background-color: rgba($white, 0.2);
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
      background-color: rgba($white, 0.1);

      @at-root {
        .no-touchevents {
          .btn--transparent-white-button {
            &.disabled,
            &:disabled {
              &:hover {
                &:before {
                  display: none;
                }

                background-color: rgba($white, 0.1);
              }
            }
          }
        }
      }
    }
  }

  &--with-icon {
    padding-left: 12px;
    padding-right: 18px;
    display: inline-flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }


  &--lg {
    font-size: 18px;
    padding: 18px 24px 18px;
  }
}