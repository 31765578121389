$z-index-modal: 999;


$gray-100: #f5f5f5;
$gray-200: #fdfdfd;
$gray-300: #e2e8f0;
$gray-400: #cbd5e0;
$gray-600: #718096;
$gray-700: #4a5568;
$gray-800: #1c1d1d;
$gray-900: #1a202c;

$gray-500: #e5e5e5;

$green-500: #18ab9b;

$text-tighter: -0.05em;
$text-tight: -0.025em;
$text-normal: 0;
$text-wide: 0.025em;
$text-wider: 0.05em;
$text-widest: 0.1em;

$text-xs: 0.75rem;
$text-sm: .875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
$text-5xl: 3rem;
$text-6xl: 4rem;

$s-1: 0.25rem;
$s-2: 0.5rem;
$s-3: 0.75rem;
$s-4: 1rem;
$s-5: 1.25rem;
$s-6: 1.5rem;
$s-8: 2rem;
$s-10: 2.5rem;
$s-12: 3rem;
$s-16: 4rem;
$s-20: 5rem;
$s-24: 6rem;
$s-32: 8rem;
$s-40: 10rem;
$s-48: 12rem;
$s-56: 14rem;
$s-64: 16rem;

$black: #000;
$white: #fff;

$body-bg: $white;
$body-color: $gray-800;

$font-family-sans-serif: 'Inter', sans-serif;
$font-family-serif: 'Inter', serif;
$font-family-monospace: monospace;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;

$line-height-base: 1.25;

$border-radius: 5px;
$border-radius-lg: 10px;

$link-color: $primary;
$link-hover-color: rgba($primary, 0.1);

$border-color: $gray-500;
// Forms

$input-height: 56px;
$input-padding-y: 16px;
$input-padding-x: 16px;
$input-font-size: 16px;
$input-line-height: 24px;

$input-border-color: $border-color;

// Buttons
$btn-height: 40px;
$btn-padding-y: 10px;
$btn-padding-x: 16px;
$btn-font-size: 14px;
$btn-line-height: 20px;

$drawer-desktop-width: 240px + 1px;
$drawer-desktop-width-condensed: 80px + 1px;


$accent-green: #18ab9b;
$accent-yellow: #feb74d;
$accent-purple: #805df5;
$accent-pink: #ff64b8;
$accent-red: #fb4747;
$accent-error: $accent-red;
$z-index-drawer: 997;
$z-index-slideout: 998;
$z-index-alert: 999;


$modal-body-padding: 32px;
$container-padding: 15px;