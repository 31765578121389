.toasted-container.top-right.toasted-container-ply {
  top: 12px;
  right: 12px;
  z-index: $z-index-alert;
}

.toasted.toasted-primary.toasted-ply {
  color: #fff;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.25);
  max-width: 310px;
  line-height: 22px;
  font-size: 14px;

  &:first-child {
    margin-top: 0;
  }

  > i {
    margin-right: 10px;
  }

  i {
    font-size: 20px;
  }

  &.error {
    background-color: $accent-error;
  }

  &.info, &.success {
    background-color: $primary;
  }

  .action {
    color: #fff;
  }

  .toast-close {
    i {
      width: 20px;
      height: 20px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='white'%3E%3Cpath d='M15.833 5.342l-1.175-1.175-4.658 4.658-4.658-4.658-1.175 1.175 4.658 4.658-4.658 4.658 1.175 1.175 4.658-4.658 4.658 4.658 1.175-1.175-4.658-4.658z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

@media only screen and (max-width: 600px) {
  .toasted-container.toasted-container-ply {
    min-width: 0;
  }

  .toasted.toasted-primary.toasted-ply {
    max-width: 300px;
  }
}
