.tooltip {
  display: block !important;
  z-index: 10000;
  max-width: 280px;
  line-height: 1.5;

  .tooltip-inner {
    background: $primary;
    color: white;
    border-radius: 2px;
    padding: 6px;
    font-size: 12px;
    font-weight: 500;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;
  }

  &[x-placement^="right"] {
    margin-left: 10px;
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.tooltip-with-menu {
  .tooltip-inner {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

.tooltip-menu {
  background-color: #fff;
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid $border-color;
  overflow: hidden;

  &__item {
    width: 100%;
    font-weight: 500;
    color: $black;
    display: block;
    background-color: $white;
    transition: background-color 0.15s ease-out;
    border-bottom: 1px solid $gray-500;
    padding: $btn-padding-y $btn-padding-x;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
  }

  @at-root {
    .no-touchevents {
      .tooltip-menu__item:hover {
        background-color: $gray-100;
      }
    }
  }
}