@keyframes placeholder-progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

@import "./abstracts/index.scss";
@import "./base/index.scss";

@import "./vendor/toasted.scss";
@import "./vendor/tooltip.scss";
@import "./vendor/chart.scss";
