body {
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt";
  font-weight: 300;
}

h1, h2, h3, .h1, .h2, .h3 {
  font-family: $font-family-serif;
  color: $black;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
}


h4, h5, h6, .h4, .h5, .h6 {
  font-family: $font-family-sans-serif;
  color: $black;
  line-height: 1.3;
  text-rendering: optimizeLegibility;
}


h1, .h1 {
  font-size: 72px;
}


h2, .h2 {
  font-size: 44px;
}


h3, .h3 {
  font-size: 36px;
}


h4, .h4 {
  font-size: 24px;
}


h5, .h5 {
  font-size: 18px;
  font-weight: 300;
}


h6, .h6 {
  font-size: 16px;
  font-weight: 300;
}


p {
  font-family: $font-family-sans-serif;
  color: $body-color;
  margin-bottom: 10px;
  line-height: 22px;

  &:last-child {
    margin-bottom: 0;
  }
}


a {
  transition: color 0.1s;
  outline: none;
}
